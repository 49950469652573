<template>
  <div>
    <div id="sidebarRightSettingTextReply" class="sidepanel">
      <div
        class="sticky-top d-flex justify-content-between align-items-center px-3"
      >
        <span class="title-side">เพิ่มข้อความตอบกลับ</span>
        <a href="javascripxt:void(0)" class="closebtn" @click="closeNav()">×</a>
      </div>
      <div class="border-top pt-2">
        <div class="px-3">
          <b-form class="mb-2">
            <div>
              <InputSelect
                title="Type"
                valueField="id"
                textField="name"
                v-bind:options="typeList"
                v-model="formTag.type"
                isRequired
                v-bind:isValidate="$v.formTag.type.$error"
                :v="$v.formTag.type"
                @onDataChange="val => (formTag.type = val)"
              />
            </div>
          </b-form>
          <b-form class="mb-2">
            <div>
              <InputText
                v-model="formTag.title"
                textFloat="Title"
                placeholder="Title"
                isRequired
                id="tagTitle"
                type="text"
                :v="$v.formTag.title"
              />
            </div>
            <div class="mt-2">
              <InputText
                v-model="formTag.shortkeys"
                textFloat="Short Keys"
                placeholder="Short Keys"
                isRequired
                id="tagShortkeys"
                type="text"
                :v="$v.formTag.shortkeys"
              />
            </div>
            <div class="mt-2">
              <b-button-group class="btn-groups">
                <b-button
                  variant="light"
                  :class="[{ active: formTag.replyType.indexOf(1) > -1 }]"
                  @click="handleChangeReplyType(1, 0)"
                >
                  <img
                    src="@/assets/images/reply-text.png"
                    height="13"
                    width="13"
                    class="icon-type"
                  />
                </b-button>
                <b-button
                  variant="light"
                  :class="[{ active: formTag.replyType.indexOf(2) > -1 }]"
                  @click="handleChangeReplyType(2, 0)"
                >
                  <font-awesome-icon icon="image" class="icon-type" />
                </b-button>
                <b-button
                  variant="light"
                  :class="[{ active: formTag.replyType.indexOf(4) > -1 }]"
                  @click="handleChangeReplyType(4, 0)"
                >
                  <font-awesome-icon icon="link" class="icon-type" />
                </b-button>
              </b-button-group>
              <p v-if="$v.formTag.replyType.$error" class="text-error">
                กรุณาเลือกรูปแบบข้อความตอบกลับอย่างน้อย 1 รูปแบบ
              </p>
            </div>
            <div class="mt-2" v-if="formTag.replyType.indexOf(1) > -1">
              <InputTextArea
                v-model="formTag.textReply"
                textFloat="Text Reply"
                placeholder="Text Reply"
                isRequired
                id="tagTextReply-1"
                rows="3"
                :v="$v.formTag.textReply"
              />
            </div>
            <div class="mt-2" v-if="formTag.replyType.indexOf(2) > -1">
              <UploadFile
                textFloat="Image Reply"
                placeholder="Please Upload"
                format="image"
                name="profile_reply_0"
                :fileName="formTag.imageReplyName"
                isRequired
                v-on:onFileChange="val => onImageChange(val, 0)"
                v-on:delete="onDeleteImage(0)"
                deleteIcon
                :v="$v.formTag.imageReply"
                :text="
                  `*Please upload only file .png, .jpg size 1:1 and less than 10 MB`
                "
              />
              <div class="w-25" v-if="formTag.imageReply">
                <PreviewFile :urlPath="formTag.imageReply" />
              </div>
            </div>
            <div class="mt-2" v-if="formTag.replyType.indexOf(4) > -1">
              <InputText
                v-model="formTag.linkReply"
                textFloat="Link"
                placeholder="Link"
                isRequired
                id="tagTextReply-4"
                type="text"
                :v="$v.formTag.linkReply"
              />
            </div>
          </b-form>
          <div class="pb-2 w-100">
            <b-overlay
              :show="isLoadingSaveTag"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block w-100"
            >
              <b-button
                @click="addTag()"
                variant="secondary"
                class="w-100"
                :disabled="isLoadingSaveTag"
                >เพิ่ม
              </b-button>
            </b-overlay>
          </div>
        </div>
        <!-- <b-form-tags
          v-show="$store.getters.getChatTextReplyList.length > 0"
          no-outer-focus
          class="mb-2"
        > -->
        <div class="mb-2 px-3">
          <div
            v-for="(tag, idx) in $store.getters.getChatTextReplyList"
            :key="tag.idx"
            class="mr-1 px-2 mb-2 py-2 keyword-box"
          >
            <div class="wrap-div-action-tag">
              <div class="div-action-tag">
                <b-button
                  v-if="!tagOpenEdit.includes(idx)"
                  variant="link"
                  @click="toggleEditTag(1, idx, tag)"
                  class="p-0"
                  >แก้ไข</b-button
                >
                <b-button
                  variant="link"
                  @click="removeTag(tag, idx)"
                  class="p-0 ml-2"
                  >ลบ</b-button
                >
              </div>
            </div>
            <div v-if="!tagOpenEdit.includes(idx)">
              <div class="d-flex flex-row">
                <div>
                  <div class="div-text-title">
                    <b-badge pill variant="badge-public" v-if="tag.type == 1"
                      >Public</b-badge
                    >
                    <b-badge
                      pill
                      variant="badge-private"
                      v-else-if="tag.type == 2"
                      >Private</b-badge
                    >
                    {{ tag.title }}
                  </div>
                  <div class="div-text-shortkeys">/{{ tag.shortkeys }}</div>
                </div>
              </div>
              <div
                class="mt-1 div-textReply text-wrap"
                v-if="tag.replyType.indexOf(1) > -1"
              >
                {{ tag.textReply }}
              </div>
              <div class="mt-1" v-if="tag.replyType.indexOf(2) > -1">
                <div class="w-25" v-if="tag.imageReply">
                  <PreviewFile :urlPath="tag.imageReply" />
                </div>
              </div>
              <div
                class="mt-1 div-textReply text-wrap"
                v-if="tag.replyType.indexOf(4) > -1"
              >
                {{ tag.linkReply }}
              </div>
            </div>
            <div v-if="tagOpenEdit.includes(idx)">
              <b-form class="mb-2">
                <div>
                  <InputSelect
                    title="Type"
                    valueField="id"
                    textField="name"
                    v-bind:options="typeList"
                    v-on:onDataChange="val => (formEditTag.type = val)"
                    v-model="formEditTag.type"
                    isRequired
                    v-bind:isValidate="$v.formEditTag.type.$error"
                    :v="$v.formEditTag.type"
                  />
                </div>
              </b-form>
              <b-form class="mb-2">
                <div>
                  <InputText
                    v-model="formEditTag.title"
                    textFloat="Title"
                    placeholder="Title"
                    isRequired
                    :id="`tagTitleEdit-${idx}`"
                    type="text"
                    :v="$v.formEditTag.title"
                  />
                </div>
                <div class="mt-2">
                  <InputText
                    v-model="formEditTag.shortkeys"
                    textFloat="Short Keys"
                    placeholder="Short Keys"
                    isRequired
                    :id="`tagShortKeysEdit-${idx}`"
                    type="text"
                    :v="$v.formEditTag.shortkeys"
                  />
                </div>
                <div class="mt-2">
                  <b-button-group class="btn-groups">
                    <b-button
                      variant="light"
                      :class="[
                        { active: formEditTag.replyType.indexOf(1) > -1 }
                      ]"
                      @click="handleChangeReplyType(1, formEditTag.id)"
                    >
                      <img
                        src="@/assets/images/reply-text.png"
                        height="13"
                        width="13"
                        class="icon-type"
                      />
                    </b-button>
                    <b-button
                      variant="light"
                      :class="[
                        { active: formEditTag.replyType.indexOf(2) > -1 }
                      ]"
                      @click="handleChangeReplyType(2, formEditTag.id)"
                    >
                      <font-awesome-icon icon="image" class="icon-type" />
                    </b-button>
                    <b-button
                      variant="light"
                      :class="[
                        { active: formEditTag.replyType.indexOf(4) > -1 }
                      ]"
                      @click="handleChangeReplyType(4, formEditTag.id)"
                    >
                      <font-awesome-icon icon="link" class="icon-type" />
                    </b-button>
                  </b-button-group>
                  <p v-if="$v.formEditTag.replyType.$error" class="text-error">
                    กรุณาเลือกรูปแบบข้อความตอบกลับอย่างน้อย 1 รูปแบบ
                  </p>
                </div>
                <div class="mt-2" v-if="formEditTag.replyType.indexOf(1) > -1">
                  <InputTextArea
                    v-model="formEditTag.textReply"
                    textFloat="Text Reply"
                    placeholder="Text Reply"
                    isRequired
                    :id="`tagTextReplyEdit-1-${idx}`"
                    rows="3"
                    :v="$v.formEditTag.textReply"
                  />
                </div>
                <div class="mt-2" v-if="formEditTag.replyType.indexOf(2) > -1">
                  <UploadFile
                    textFloat="Image Reply"
                    placeholder="Please Upload"
                    format="image"
                    :name="`profile_reply_${formEditTag.id}`"
                    :fileName="
                      formEditTag.imageReplyName || formEditTag.imageReply
                    "
                    isRequired
                    v-on:onFileChange="
                      val => onImageChange(val, formEditTag.id)
                    "
                    v-on:delete="onDeleteImage(formEditTag.id)"
                    deleteIcon
                    :v="$v.formEditTag.imageReply"
                    :text="
                      `*Please upload only file .png, .jpg size 1:1 and less than 10 MB`
                    "
                  />
                  <div class="w-25" v-if="formEditTag.imageReply">
                    <PreviewFile :urlPath="formEditTag.imageReply" />
                  </div>
                </div>
                <div class="mt-2" v-if="formEditTag.replyType.indexOf(4) > -1">
                  <InputText
                    v-model="formEditTag.linkReply"
                    textFloat="Link"
                    placeholder="Link"
                    isRequired
                    :id="`tagTextReplyEdit-4-${idx}`"
                    type="text"
                    :v="$v.formEditTag.linkReply"
                  />
                </div>
                <div class="pt-2 d-flex w-100">
                  <div class="ml-auto">
                    <b-button
                      @click="toggleEditTag(2, idx, tag)"
                      variant="secondary"
                      size="sm"
                      class="btn-cancel-edit"
                      >ยกเลิก</b-button
                    >
                    <b-button
                      @click="submitEditTag()"
                      variant="secondary"
                      size="sm"
                      class="ml-1"
                      :disabled="isLoadingSaveTag"
                      >บันทึก</b-button
                    >
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
        <!-- </b-form-tags> -->
        <div
          v-if="
            $store.getters.getChatTextReplyList.length == 0 &&
              !$store.state.isLoadingChatTextReply
          "
          class="text-center w-100 py-4"
        >
          <span class="">ไม่มีข้อความตอบกลับ</span>
        </div>
        <div
          v-if="
            $store.getters.getChatTextReplyList.length == 0 &&
              $store.state.isLoadingChatTextReply
          "
          class="text-center w-100 py-4"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import UploadFile from "@/components/inputs/UploadFile";
import PreviewFile from "@/components/inputs/PreviewFile";
import { mapGetters } from "vuex";

import {
  required,
  numeric,
  minValue,
  requiredIf,
  url,
  minLength
} from "vuelidate/lib/validators";

export default {
  name: "ChatSideBarSetReply",
  components: {
    InputText,
    InputTextArea,
    UploadFile,
    PreviewFile,
    InputSelect
  },
  data() {
    return {
      formTag: {
        type: 1,
        title: "",
        shortkeys: "",
        textReply: "",
        replyType: [1],
        imageReply: "",
        imageReplyName: "",
        linkReply: ""
      },
      formEditTag: {
        id: "",
        type: 1,
        title: "",
        shortkeys: "",
        textReply: "",
        replyType: [],
        imageReply: "",
        imageReplyName: "",
        linkReply: ""
      },
      isLoadingSaveTag: false,
      tagOpenEdit: [],
      typeList: [
        {
          id: 1,
          name: "Public"
        },
        {
          id: 2,
          name: "Private"
        }
      ],
      selectFristType: 1
    };
  },
  validations() {
    return {
      formTag: {
        type: {
          required,
          numeric,
          minValue: minValue(1)
        },
        title: { required },
        shortkeys: { required },
        replyType: {
          required,
          minLength: minLength(1)
        },
        textReply: {
          required: requiredIf(function() {
            return this.formTag.replyType.indexOf(1) > -1;
          })
        },
        imageReply: {
          required: requiredIf(function() {
            return this.formTag.replyType.indexOf(2) > -1;
          })
        },
        imageReplyName: {
          required: requiredIf(function() {
            return this.formTag.replyType.indexOf(2) > -1;
          })
        },
        linkReply: {
          url,
          required: requiredIf(function() {
            return this.formTag.replyType.indexOf(4) > -1;
          })
        }
      },
      formEditTag: {
        type: {
          required,
          numeric,
          minValue: minValue(1)
        },
        title: { required },
        shortkeys: { required },
        replyType: {
          required,
          minLength: minLength(1)
        },
        textReply: {
          required: requiredIf(function() {
            return this.formEditTag.replyType.indexOf(1) > -1;
          })
        },
        imageReply: {
          required: requiredIf(function() {
            return this.formEditTag.replyType.indexOf(2) > -1;
          })
        },
        imageReplyName: {
          required: requiredIf(function() {
            return (
              this.formEditTag.replyType.indexOf(2) > -1 &&
              !this.formEditTag.imageReply
            );
          })
        },
        linkReply: {
          url,
          required: requiredIf(function() {
            return this.formEditTag.replyType.indexOf(4) > -1;
          })
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      brandId: "getBrand",
      isMobile: "getIsMobile"
    })
  },
  methods: {
    openNav() {
      document.getElementById("sidebarRightSettingTextReply").style.width =
        this.$route.query.isMobile == "true" || this.isMobile
          ? "100%"
          : "400px";
    },
    closeNav() {
      this.clearTag();
      document.getElementById("sidebarRightSettingTextReply").style.width = "0";
    },
    clearTag() {
      this.formTag.type = this.selectFristType;
      this.formTag.title = "";
      this.formTag.shortkeys = "";
      this.formTag.textReply = "";
      this.formTag.replyType = [1];
      this.formTag.imageReply = "";
      this.formTag.imageReplyName = "";
      this.formTag.linkReply = "";
    },
    addTag() {
      this.$v.formTag.$touch();
      if (this.$v.formTag.$error) return;

      this.isLoadingSaveTag = true;
      this.$axios
        .post(this.$chatBaseUrl + `/reply/save`, {
          brandId: this.brandId,
          userGUID: this.$store.state.chatAdminProfile.externalId,
          type: this.formTag.type,
          title: this.formTag.title,
          shortkeys: this.formTag.shortkeys,
          textReply: this.formTag.textReply,
          replyType: this.formTag.replyType,
          imageReply: this.formTag.imageReply,
          imageReplyName: this.formTag.imageReplyName,
          linkReply: this.formTag.linkReply,
          updatedTime: "2021-12-29T19:50:27.602Z",
          createdTime: "2021-12-29T19:50:27.602Z"
        })
        .then(response => {
          this.$v.formTag.$reset();
          this.isLoadingSaveTag = false;
          this.$store.dispatch("getChatTextReplyAdmin");
          if (response.data.result == 1) {
            this.clearTag();
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.detail
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          this.isLoadingSaveTag = false;
          console.log(error);
          this.$store.dispatch("getChatTextReplyAdmin");
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    },
    removeTag(val, idx) {
      this.$store.commit("SPLICE_TEXT_REPLY_LIST", { idx: idx });
      this.$axios
        .delete(this.$chatBaseUrl + `/reply/${val.id}`)
        .then(response => {
          this.$v.formEditTag.$reset();
          if (!response.data.result == 1) {
            this.$store.dispatch("getChatTextReplyAdmin");
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.detail
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch("getChatTextReplyAdmin");
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    },
    toggleEditTag(flag, idx, tagVal) {
      const {
        id,
        type,
        title,
        shortkeys,
        textReply,
        replyType,
        imageReply,
        imageReplyName,
        linkReply
      } = tagVal;
      this.tagOpenEdit = [];
      this.formEditTag = {
        id: "",
        type: 1,
        title: "",
        shortkeys: "",
        textReply: "",
        replyType: [],
        imageReply: "",
        imageReplyName: "",
        linkReply: ""
      };
      if (flag == 1) {
        this.formEditTag = {
          id: id,
          type: type,
          title: title,
          shortkeys: shortkeys,
          textReply: textReply,
          replyType: replyType,
          imageReply: imageReply,
          imageReplyName: imageReplyName,
          linkReply: linkReply
        };
        this.tagOpenEdit.push(idx);
      }
    },
    submitEditTag() {
      this.$v.formEditTag.$touch();
      if (this.$v.formEditTag.$error) return;

      this.isLoadingSaveTag = true;
      this.$axios
        .post(this.$chatBaseUrl + `/reply/save`, {
          brandId: this.brandId,
          userGUID: this.$store.state.chatAdminProfile.externalId,
          id: this.formEditTag.id,
          type: this.formEditTag.type,
          title: this.formEditTag.title,
          shortkeys: this.formEditTag.shortkeys,
          textReply: this.formEditTag.textReply,
          replyType: this.formEditTag.replyType,
          imageReply: this.formEditTag.imageReply,
          imageReplyName: this.formEditTag.imageReplyName,
          linkReply: this.formEditTag.linkReply,
          updatedTime: "2021-12-29T19:50:27.602Z",
          createdTime: "2021-12-29T19:50:27.602Z"
        })
        .then(response => {
          this.isLoadingSaveTag = false;
          this.$v.formEditTag.$reset();

          this.$store.dispatch("getChatTextReplyAdmin");
          if (response.data.result == 1) {
            this.toggleEditTag(2, 0, {});
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.detail
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          this.isLoadingSaveTag = false;
          console.log(error);
          this.$store.dispatch("getChatTextReplyAdmin");
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    },
    handleChangeReplyType(replyType, tagId) {
      if (tagId == 0) {
        let index = this.formTag.replyType.indexOf(replyType);
        if (index < 0) this.formTag.replyType.push(replyType);
        else
          this.formTag.replyType = this.formTag.replyType.filter(
            ele => ele != replyType
          );
      } else {
        let index = this.formEditTag.replyType.indexOf(replyType);
        if (index < 0) this.formEditTag.replyType.push(replyType);
        else
          this.formEditTag.replyType = this.formEditTag.replyType.filter(
            ele => ele != replyType
          );
      }
    },
    onImageChange(value, id) {
      let keyName = "formEditTag";
      if (id == 0) keyName = "formTag";
      this[keyName].imageReply = "";
      this[keyName].imageReplyName = "";
      if (value) {
        this[keyName].imageReplyName = value.name; //`${value.name}.${value.type.split("/").pop(-1)}`; //value.name;
        var reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = () => {
          this[keyName].imageReply = reader.result;
        };
      }
    },
    onDeleteImage(id) {
      let keyName = "formEditTag";
      if (id == 0) keyName = "formTag";
      this[keyName].imageReply = "";
      this[keyName].imageReplyName = "";
    }
  }
  // created() {
  //   this.$store.dispatch("getChatTextReplyAdmin");
  // }
};
</script>

<style lang="scss" scoped>
.sidepanel {
  width: 0;
  position: fixed;
  z-index: 1039;
  height: 100dvh;
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  transition: 0.5s;
  // padding-top: 52px;
  color: #707070;
  border-left: 1px solid #b3afaf6e;
}

.sidepanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #707070;
  display: block;
  transition: 0.3s;
}

.sidepanel .closebtn {
  font-size: 23px;
}
.sidepanel .title-side {
  font-size: 16px;
}

::v-deep button.close {
  margin-left: 10px;
  margin-top: -8px;
}
::v-deep button.close:focus,
::v-deep button.close:focus-within {
  outline: 0px dotted;
}
::v-deep .b-form-tag-content {
  margin: auto 0;
}
::v-deep .btn-secondary {
  background-color: #832abf !important;
  border-color: #832abf;
  color: #fff;
}
.div-text-title {
  font-size: 16px;
  font-weight: 400;
  max-width: 14rem;
  word-wrap: break-word;
  line-height: 1.2;
  margin-bottom: 4px;
}
.div-text-shortkeys {
  font-size: 13px;
  font-weight: 400;
  max-width: 20rem;
  word-wrap: break-word;
  line-height: 1.2;
}
.div-textReply {
  font-size: 12px;
  font-weight: 400;
  color: #000;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  min-height: 1.2rem;
  line-height: 1.2rem;
  max-height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.keyword-box {
  background-color: #e2dff3;
  color: #2c3e50;
  white-space: break-spaces;
}
.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
  border: 0.25em solid #832abf;
  border-right-color: transparent;
}
.wrap-div-action-tag {
  position: relative;
}
.div-action-tag {
  position: absolute;
  top: 0;
  right: 2px;
}
.btn-cancel-edit {
  color: #832abf !important;
  background-color: #ffffff !important;
  border: 1px solid #9450c2;
}
.btn-groups {
  button {
    background-color: #fff;
    color: #d6d6d6;
    border-color: #dbdbdb;
    margin-left: 0px !important;
  }
  button:nth-child(2) {
    border-left-color: #dbdbdb !important;
    border-right-color: #dbdbdb !important;
  }
  button.active {
    border-color: rgb(113, 96, 196);
    background-color: rgb(113, 96, 196);
    color: #fff;
  }
  button:hover {
    border-color: #d6d6d6;
  }
}
.badge-badge-private {
  color: #fff;
  background-color: #832abf;
  border: 1px solid #832abf;
}
.badge-badge-public {
  background-color: #fff;
  color: #832abf;
  border: 1px solid #832abf;
  font-size: 10px;
}
#sidebarRightSettingTextReply {
  .sticky-top {
    background-color: white;
  }
}
@media (max-width: 767.98px) {
  .sidepanel {
    z-index: 1039;
  }
}
</style>
